import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Button, Col, Form, Modal, Row, Spin,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormSection from '../../../components/Form/FormSection';
import TelematicProviderChooserSection from './TelematicProviderChooserSection';
import TelematicAccountDetailsSection from './TelematicAccountDetailsSection';
import { fetchTelematicAccountFormConfig, submitTelematicAccountSupportRequired } from '../../../store/actions';
import { isError, isSuccess } from '../../../utils/responseUtils';
import { uuidv4 } from '../../../utils/uuid';
import {
  getSelectedCompany,
  getTelematicAccountFormConfig,
} from '../../../store/selectors';
import { Styles } from './TelematicAccount.styled';
import { FormItem } from '../../../components';
import { SpinnerIcon } from '../../../components/Structure/Loader/Loader';

function supportNeededModalKeysAndConstructor(forSuccess, forFailure) {
  const statusSuffix = (forSuccess && 'SUCCESS') || (forFailure && 'ERROR') || 'STARTED';
  const modalConstructor = (forSuccess && Modal.success) || (forFailure && Modal.error) || Modal.info;
  return {
    title: `TELEMATICS.ACCOUNT.CREATE.CONFIRMATION.TITLE.NEED_SUPPORT.${statusSuffix}`,
    content: `TELEMATICS.ACCOUNT.CREATE.CONFIRMATION.CONTENT.NEED_SUPPORT.${statusSuffix}`,
    constructor: modalConstructor,
  };
}

function preparePayloadForSupportNeeded(telematicAccountId, {
  accountDisplayName,
  accountDescription,
  selectedProvider,
  notFromListProviderCheckbox,
  notFromListProviderName,
  notFromListProviderContactEmail,
  notFromListProviderLink,
  ...otherParams
}) {
  return {
    providerId: selectedProvider,
    providerAccountId: telematicAccountId,
    credentials: otherParams,
  };
}

export default function TelematicAccountForm({
  form,
  submitDispatchFunction,
  submitLabelKey,
  telematicAccountId,
  initialValues,
  creationView,
  submitClicked,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const contextCompanyId = useSelector(getSelectedCompany);
  const { telematicAccountFormConfig, telematicAccountFormConfigLoading } = useSelector(getTelematicAccountFormConfig);

  useEffect(() => {
    dispatch(fetchTelematicAccountFormConfig());
  }, [dispatch]);

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [isDetailsDisabled, setDetailsDisabled] = useState(null);
  const [validExistingProviderForm, setValidExistingProviderForm] = useState(!creationView);
  const [validNewProviderForm, setValidNewProviderForm] = useState(false);

  const actionName = creationView ? 'CREATE' : 'UPDATE';
  const isActiveButton = (form.getFieldValue('notFromListProviderCheckbox') && creationView)
    ? validNewProviderForm
    : validExistingProviderForm;
  const selectedProviderHasNoFieldsOnCreationView = (provider) => creationView && provider
    && provider.fields?.length === 0;

  const handleUserNeedsSupport = () => {
    const payload = preparePayloadForSupportNeeded(telematicAccountId, form.getFieldsValue());
    dispatch(submitTelematicAccountSupportRequired(payload, contextCompanyId))
      .then((response) => {
        const modalInfo = supportNeededModalKeysAndConstructor(
          isSuccess(response),
          isError(response),
        );
        modalInfo.constructor({
          key: uuidv4(),
          title: t(modalInfo.title),
          content: t(modalInfo.content),
          onOk: () => {
            history.push('/telematics');
          },
          centered: true,
          closable: true,
          maskClosable: true,
        });
      });
  };

  function transformTelematicAccountOrUnknownProviderFormToPayload({
    accountDisplayName,
    accountDescription,
    notFromListProviderCheckbox,
    notFromListProviderName,
    notFromListProviderContactEmail,
    notFromListProviderLink,
    ...otherParams
  }) {
    const payload = notFromListProviderCheckbox ? {
      providerName: notFromListProviderName,
      providerContactEmail: notFromListProviderContactEmail,
      providerWebsiteLink: notFromListProviderLink,
    } : {
      accountDescription,
      providerId: selectedProvider.providerId,
      formAttributes: otherParams,
    };

    return {
      payload,
      isUnknownProvider: notFromListProviderCheckbox,
      handleUserNeedsSupport,
    };
  }

  const handleSubmit = () => {
    form.validateFields()
      .then(transformTelematicAccountOrUnknownProviderFormToPayload)
      .then(submitDispatchFunction);
  };

  const resetAccountDetailsFields = (provider) => {
    provider.fields.forEach((field) => {
      form.resetFields([field.fieldName]);
    });
    setValidExistingProviderForm(false);
  };

  const validateNewProvider = () => {
    const newProviderFields = ['notFromListProviderName', 'notFromListProviderContactEmail', 'notFromListProviderLink'];
    if (form.isFieldsTouched(newProviderFields, true)) {
      form.validateFields()
        .then(() => {
          setValidNewProviderForm(true);
        }).catch(() => {
          setValidNewProviderForm(false);
        });
    } else {
      setValidNewProviderForm(false);
    }
  };

  const validateExistingProvider = (provider) => {
    const existingProviderFields = ['selectedProvider'];
    const existingProviderFieldsTouched = form.isFieldsTouched(existingProviderFields, true) && provider
      && provider.fields.every((field) => form.getFieldValue(field.fieldName) || field.optional);
    const fieldsTouchedOrEditView = existingProviderFieldsTouched || !creationView;

    if (fieldsTouchedOrEditView && !selectedProviderHasNoFieldsOnCreationView(provider)) {
      form.validateFields()
        .then(() => {
          setValidExistingProviderForm(true);
        }).catch(() => {
          setValidExistingProviderForm(false);
        });
    } else {
      setValidExistingProviderForm(false);
    }
  };

  const validateForm = (event) => {
    if (event?.target?.id === 'notFromListProviderCheckbox') {
      return;
    }
    if (form.getFieldValue('notFromListProviderCheckbox') && creationView) {
      validateNewProvider();
    } else {
      validateExistingProvider(selectedProvider);
    }
  };

  const renderSingleInstructionLink = ({ lang, url }) => {
    const staticLabel = t('TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.INSTRUCTION_LINK_LABEL');
    const linkText = `${lang} ${staticLabel}`;
    return (
      <li key={uuidv4()}>
        <a href={url} target="_blank" rel="noopener noreferrer">{linkText}</a>
      </li>
    );
  };
  const renderIntructionLinks = (links) => links
    .sort((a, b) => a.lang.localeCompare(b.lang))
    .map(renderSingleInstructionLink);

  const renderDetailsBlueBoxDescription = () => {
    const links = selectedProvider.assistance.instructionLinks;
    const hasLinks = links.length > 0;
    const descriptionKey = hasLinks
      ? 'DESCRIPTION_FOR_LINKS'
      : 'DESCRIPTION_NO_LINKS';
    return (
      <>
        {hasLinks && <p>{t('TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.INSTRUCTION_LINKS_HEADER')}</p>}
        <ul>{renderIntructionLinks(links)}</ul>
        <p>{t(`TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.${descriptionKey}`)}</p>
      </>
    );
  };

  return (
    <>
      <Spin
        spinning={submitClicked}
        style={{ maxHeight: '100%' }}
        indicator={(
          <SpinnerIcon size="m" />
        )}
      >
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          form={form}
          style={{ marginTop: '12px', marginBottom: '40px', width: '1100px' }}
          layout="vertical"
          disabled={telematicAccountFormConfigLoading}
          onChange={(event) => validateForm(event)}
        >
          <FormSection title={t(`TELEMATICS.ACCOUNT.${actionName}.SECTIONS.PROVIDER.TITLE`)}>
            <Row justify="left" align="top">
              <TelematicProviderChooserSection
                providers={telematicAccountFormConfig}
                initialProviderId={initialValues.selectedProvider}
                selectedProvider={selectedProvider}
                setSelectedProvider={setSelectedProvider}
                setDetailsDisabled={setDetailsDisabled}
                creationView={creationView}
                resetAccountDetailsFields={resetAccountDetailsFields}
              />
            </Row>
          </FormSection>

          {!isDetailsDisabled && selectedProvider ? (
            <FormSection title={t('TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.TITLE')}>
              <Row justify="left" align="top">
                <Col span={11}>
                  {selectedProvider?.fields?.length > 0 ? (
                    <TelematicAccountDetailsSection
                      onSelectChange={(value) => validateForm(value)}
                      selectedProvider={selectedProvider}
                      isDetailsDisabled={isDetailsDisabled}
                      creationView={creationView}
                    />
                  ) : (
                    <>
                      <Alert
                        type="info"
                        showIcon
                        description={t('TELEMATICS.ACCOUNT.CREATE.HAS_NO_ATTRIBUTES.INFO')}
                        style={Styles.wide}
                      />
                      <div style={{ marginTop: '16px' }}>
                        <FormItem
                          key={uuidv4()}
                          label={t('TELEMATICS.ACCOUNT.CREATE.HAS_NO_ATTRIBUTES.INPUT_LABEL')}
                          name="supportInfo"
                          style={Styles.narrow}
                        >
                          <TextArea style={Styles.wide} />
                        </FormItem>
                      </div>
                    </>
                  )}
                </Col>

                {selectedProvider.assistance.noAssistance
                  ? (
                    <>
                    </>
                  ) : (
                    <Col span={13}>
                      <Row justify="end" align="top">
                        <Alert
                          type="info"
                          showIcon
                          message={
                            <b>{t('TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.MESSAGE')}</b>
                          }
                          description={renderDetailsBlueBoxDescription()}
                        />
                      </Row>
                    </Col>
                  )}
              </Row>
            </FormSection>
          )
            : <></>}

          <Row align="top" style={{ marginTop: '16px' }}>
            <Col span={11} style={Styles.wide}>
              <Row>
                <Col span={12}>
                  {!isDetailsDisabled && (
                  <Row justify="start">
                    <Button
                      size="large"
                      style={{ borderColor: '#40a9ff', color: '#40a9ff' }}
                      onClick={handleUserNeedsSupport}
                    >
                      {t('TELEMATICS.ACCOUNT.CREATE.BUTTON_NEED_SUPPORT')}
                    </Button>
                  </Row>
                  )}
                </Col>
                <Col span={12}>
                  <Row justify="end">
                    <Button
                      type="link"
                      size="large"
                      style={{ marginRight: '20px' }}
                      onClick={() => (history.push('/telematics'))}
                    >
                      {t('COMMON.CANCEL')}
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      disabled={selectedProviderHasNoFieldsOnCreationView(selectedProvider)}
                      onClick={handleSubmit}
                      style={isActiveButton && !selectedProviderHasNoFieldsOnCreationView(selectedProvider)
                        ? { background: '#1890ff', borderColor: '#1890ff' }
                        : { background: '#BDBDBD', borderColor: '#BDBDBD' }}
                    >
                      {t(submitLabelKey)}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
}

TelematicAccountForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any.isRequired,
  submitDispatchFunction: PropTypes.func.isRequired,
  submitLabelKey: PropTypes.string,
  telematicAccountId: PropTypes.string,
  initialValues: PropTypes.shape({
    accountDisplayName: PropTypes.string,
    accountDescription: PropTypes.string,
    selectedProvider: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    credentials: PropTypes.any,
  }),
  creationView: PropTypes.bool,
  submitClicked: PropTypes.bool.isRequired,
};

TelematicAccountForm.defaultProps = {
  submitLabelKey: 'COMMON.SUBMIT',
  telematicAccountId: undefined,
  initialValues: {},
  creationView: false,
};
